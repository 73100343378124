<template>
  <div class="detalheapontamento">
    <v-tabs v-model="tab" bg-color="primary" color="white" slider-color="secondary" selected-class="color-tab"
      style="flex-shrink: 1; min-height: 30px">
      <v-tab value="historico" style="width: 40%; min-width: 20%; max-width: 350px; height: 100%">HISTÓRICO</v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab" style="width: 100%">
      <v-tab-item value="historico">
        <v-row id="container-events-mp" cols="12" class="pt-0">
          <v-col cols="12" xs="12" sm="12" md="12" id="v-col-historico">
            <v-card color="#EEEEEE" class="cardEvents" rounded="0">
              <v-toolbar height="60" color="#37474F">
                <template v-slot:append id="toolbar-historico">
                  <v-row justify="end" class="mt-3">
                    <div style="display: flex; align-items: center">
                      <v-btn @click="onShowHistorico" color="#EEEEEE" class="mb-5" variant="flat" text
                        prepend-icon="mdi mdi-magnify">
                        HISTÓRICOS
                      </v-btn>
                      <v-checkbox class="pt-0 pb-0" label="FALTANDO INFORMAÇÕES"
                        v-model="ShowInfosFaltando"></v-checkbox>
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <v-btn class="mb-5" icon="mdi mdi-filter-menu-outline" color="white" v-bind="props"></v-btn>
                          <p class="p-filtro-selecionado">
                            {{ FiltroSelecionado }}
                          </p>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in itemsFiltro" :key="index" :value="index"
                            @click="FiltroSelecionado = item.title">
                            {{ item.title }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-row>
                </template>
              </v-toolbar>
              <div style="background-color: #37474f; display: block">
                <ejs-grid :dataSource="appStore.apontamentos_historico" :rowDataBound="onRowDataBound">
                  <e-columns>
                    <e-column headerText="" field="status" :template="'columnTemplate'" width="80"
                      textAlign="Center"></e-column>
                    <e-column headerText="APONTAMENTO" field="name" width="80" textAlign="Center"></e-column>
                    <e-column headerText="STATUS DA PRODUÇÃO" field="tipo" width="80" textAlign="Center"></e-column>

                    <e-column headerText="INICIO" width="100" :template="'inicioTemplate'"
                      textAlign="Center"></e-column>

                    <e-column headerText="FIM" width="100" :template="'fimTemplate'" textAlign="Center"></e-column>

                    <e-column headerText="OP" width="100" :template="'ordem_producao'" field="ordem_de_producao"
                      textAlign="Center"></e-column>
                    <e-column headerText="TURNO" width="100" field="turno" textAlign="Center"></e-column>
                    <e-column headerText="MOTIVO DE PARADA" width="100" field="desc_motivo_parada"
                      textAlign="Center"></e-column>
                    <e-column headerText="FUNCIONARIO" width="100" field="nome_operador" textAlign="Left"></e-column>
                    <e-column headerText="OBSERVAÇÃO" width="100" :template="'obsTemplate'" textAlign="Center"></e-column>>
                  </e-columns>
                  <template v-slot:obsTemplate="{ data }">
                    <v-tooltip :text="data.observacao">
                      <template v-slot:activator="{ props }">
                        <div v-bind="props">{{ data.observacao }}</div>
                      </template>
                    </v-tooltip>
                  </template>
                  <template v-slot:dt_inicio_template="{ data }">
                    <small style="font-weight: bold;font-size: 15px;">{{ retornaDataFormatada(data.dt_inicio) }}</small>
                  </template>
                  <template v-slot:ordem_producao="{ data }">
                    <div class="d-flex align-center">
                      <v-btn variant="text" size="35" @click="ShowOrdemDeProducaoHistorico(data)" color="secondary"
                        class="mr-2">
                        <v-icon color="white" size="35">mdi-magnify</v-icon>
                      </v-btn>

                      <v-btn v-if="data.ordem_de_producao" variant="text" size="35" @click="ShowDetalheOS(data)"
                        color="secondary" class="ml-2 mr-2">
                        <v-icon color="white" size="35">mdi-folder-open</v-icon>
                      </v-btn>
                      <small style="font-weight: bold;font-size: 15px;">{{ data.ordem_de_producao }}</small>
                    </div>
                  </template>

                  <template v-slot:columnTemplate="{ data }">
                    <div class="actions">
                      <v-btn block size="60" @click="ShowApontamento(data)" color="secondary">
                        <v-icon size="35">mdi mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-slot:inicioTemplate="{ data }">
                    <div>{{ retornaDataFormatada(data.dt_inicio) }}</div>
                  </template>
                  <template v-slot:fimTemplate="{ data }">
                    <div>{{ retornaDataFormatada(data.dt_fim) }}</div>
                  </template>
                </ejs-grid>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-window>
  </div>
</template>

<script>
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Sort,
  dataReady,
} from "@syncfusion/ej2-vue-grids";

import {
  ColorMaquinaOff,
  ColorMaquinaOn,
  ColorMaquinaSetup,
  ColorManuntencao,
} from "../common/appColors";
///
import { useAppMaquinas } from "../../stores/appMaquinas";

import { useAppApontamento } from "../../stores/appApontamento";

import { useAppStore } from "../../stores/app";

import { useAppOrdemProducao } from "../../stores/appOrdemProducao";
// import { moment } from 'm'// //
import { formatDate, colorStatusParada } from "../utils/funcoes";

export default {
  name: "detalheApontamento",
  components: {
    "ejs-grid": GridComponent,
    "e-columns": ColumnsDirective,
    "e-column": ColumnDirective,
  },
  mounted() {
    useAppMaquinas().listar_maquinas();
  },
  provide: {
    grid: [Page, Edit, Toolbar, Sort],
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  watch: {
    async ShowInfosFaltando(newValue) {
      if (newValue) {
        const apontamentos_atual = useAppStore().apontamentos_historico

        useAppStore().apontamentos_historico = apontamentos_atual.filter((apontamento) => {
          if (apontamento.status === "Aberto") {
            return false;
          }

          if (apontamento.tipo === "Produção") {
            return !apontamento.ordem_de_producao || !apontamento.nome_operador;
          }

          if (apontamento.tipo === "Parada") {
            return !apontamento.desc_motivo_parada;
          }

          return true;
        });
      } else {
        await useAppStore().filtrar_apontamentos(false, 15)
      }
    },
  },
  methods: {
    async ShowOrdemDeProducaoHistorico(data) {
      useAppApontamento().set_apontamento(data);
      await useAppOrdemProducao().listar_ops(0)
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },
    retornaDataFormatada(data) {
      return formatDate(data)
    },
    async ShowDetalheOS(data) {
      await useAppOrdemProducao().listar_files_ordem_producao(data.ordem_de_producao);
      useAppStore().showModalObsDetalhes = true;
    },
    onShowHistorico() {
      useAppStore().showModalHistorico = true
    },//
    ShowApontamento(data) {
      useAppApontamento().titulo_modal = `INÍCIO DO APONTAMENTO: ${formatDate(
        data.dt_inicio
      )} - FIM DO APONTAMENTO: ${formatDate(data.dt_fim)}`;
      useAppApontamento().set_apontamento(data);
      useAppApontamento().showModal = true;
    },
    onRowDataBound(args) {
      // console.log('entrei aqui onRowDataBound')
      // console.log('detalhes de aponta', args.data, args.data.name, colorStatusParada(args.data.motivo_de_parada_manutencao_setup) )
      switch (args.data.tipo) {
        case "Produção":
          args.row.style.backgroundColor = '#43A047';
          args.row.style.color = "white";
          break;
        case "Parada":
          args.row.style.backgroundColor = colorStatusParada(args.data.motivo_de_parada_manutencao_setup);
          args.row.style.color = "white";
          break;
        default:
          args.row.style.backgroundColor = "#f8d7da";
          args.row.style.color = "white";
          break;
      }
    },
    CustomizaLinha(status_producao) {
      let style = `
                    font-weight: bold; 
                    border-radius: 5px; 
                    height: 85px;
                    max-height: 85px;
                `;

      switch (status_producao) {
        case 0:
          return `${style + "color: white; background-color: " + ColorMaquinaOff
            }`;
        case 1:
          return `${style + "color: white; background-color: " + ColorMaquinaOn
            }`;
        case 2:
          return `${style + "color: black; background-color: " + ColorMaquinaSetup
            }`;
        case 3:
          return `${style + "color: white; background-color: " + ColorManuntencao
            }`;
      }
    },
  },
  data() {
    return {
      tab: "historico",
      FiltroSelecionado: localStorage.getItem("TypesInformation"),
      ShowInfosFaltando: false,
      noDataText: "SEM INFORMAÇÕES PENDENTES",
    };
  },
};
</script>

<style>
.cardEvents {
  width: 100%;
}
</style>
